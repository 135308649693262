.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: #c2c2c2;
  padding: 0 2rem;

  &__navlink {
    margin-left: 3rem;

    .navlink {
      font-size: 1.5rem;
      font-weight: 600;
      border-bottom: 1px solid transparent;
      text-decoration: none;
      margin: 0 1rem;
      color: #005994;
    }
    .selected {
      color: #005994;
      border-bottom: 5px solid #8c8c8c;
    }
  }

  &__profileMenu {
    display: flex;
    cursor: pointer;
    align-items: center;

    .MuiAvatar-img {
      object-fit: contain;
    }

    .avatar {
      margin-right: 0.5rem;
    }
    .username {
      font-size: 1.2rem;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
}

.customDropdown {
  position: absolute;
  background-color: #ccdeea;
  width: 150px;
  top: 70px;
  right: -1.5rem;
  z-index: 200;

  .menu__item {
    color: #005994;
    padding: 0.8rem;
    font-size: 1.1rem;
    cursor: pointer;

    &:hover {
      background-color: #c7d8e3;
    }
  }
}

.drawer {
  &__btn {
    color: #005994 !important;
  }
}
