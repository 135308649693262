.customModal {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 6px;
  padding: 1.5rem 3rem;

  &__img {
    width: 70px;
    height: 80px;
    object-fit: contain;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: center;
    width: 100%;

    .title {
      font-size: 26px;
      color: #285cb8;
      text-align: center;
    }

    .childrenContainer {
      display: flex;
      justify-content: center;
      flex: 1;
    }

    .btnContainer {
      align-self: center;
      display: flex;
      column-gap: 1.5rem;
      justify-content: flex-end;

      .btn {
        min-width: 170px;
        height: 50px;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        text-transform: none;
        font-size: 1rem;
      }

      .confirmBtn {
        background: #005994;
        color: #fff;
      }
      .cancelBtn {
        background: #ffffff;
        color: #005994;
        border: 1px solid #787d86;
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 625px) {
  .customModal {
    flex-direction: column;
    padding: 1rem;
  }
  .customModal__content .btnContainer {
    flex-direction: column;
    row-gap: 1rem;
  }
}
