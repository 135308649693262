.editLicenseDetailsPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__title {
    color: #787d86;
    text-align: center;
    max-width: 1000px;
    font-weight: 600;
    margin: 0 auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem auto;
    width: 75%;
    min-height: 65vh;
    margin-bottom: 3rem;

    .input-container {
      .input {
        border: 1px solid #ccc;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        // max-width: 400px;
        width: 100%;
        margin-top: 5px;
        font-size: 1rem;
        outline: none;

        &:disabled {
          background-color: #efefef4d;
          color: #848584;
          opacity: 1;
          cursor: not-allowed;
        }
      }
    }

    .btnContainer {
      display: flex;
      width: fit-content;
      margin: 5rem auto 0;
      gap: 2rem;

      .btn {
        min-width: 250px;
        min-height: 56px;

        font-size: 1.1rem;
        text-transform: none;
        border-radius: 10px;
        padding: 5px;
      }
      .cancel {
        color: #005994;
        background-color: #fff;
        border: 2px solid #005994;
      }
      .submit {
        color: #fff;
        background-color: #005994;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .licenseSetailsPage__Container {
    min-height: 55vh;
    height: max-content !important;
  }
}

@media screen and (max-width: 1100px) {
  .editLicenseDetailsPage {
    flex-direction: column;
  }
}

@media screen and (max-width: 520px) {
  .editLicenseDetailsPage {
    padding: 0 1rem;
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
    &__container {
      padding: 2rem 1rem;

      .gridContainer {
        max-height: max-content;
      }
    }
  }
}

@media screen and (max-width: 445px) {
  .editLicenseDetailsPage {
    &__imageContainer .img {
      width: 350px;
    }
  }
}
