.welcomePage {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4rem;
  margin: 2rem 0;
  width: 100%;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__imageContainer {
    width: 100%;
    max-width: 500px;
    padding: 0 1rem;
    .img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1100px) {
  .welcomePage {
    flex-direction: column-reverse;
    row-gap: 2rem;
  }
}
