.emailVerificationPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  .firstTypo {
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    color: #000000;
  }

  .secondTypo {
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    color: #005994;
    margin-bottom: 2rem;
  }

  .thirdTypo {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #a3a3a3;
    max-width: 600px;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .fourthTypo {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 3rem;
  }

  .btn {
    border-radius: 8px;
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: none;
    padding: 0.5rem;
    width: 270px;
    margin: 0 0.75rem;
    background: #005994;
    color: #fff;
    margin-top: 3rem;
  }
}
