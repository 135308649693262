.licenseDetailsPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  .license-information {
    border: 2px solid #005994;
    border-radius: 16px;
    padding: 1.2rem 1.5rem;
    background-color: #fff;
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 700px;
    width: 100%;

    .info {
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      color: #005994;
      margin-top: 4px;

      strong {
        color: #000;
      }
    }
  }

  &__title {
    color: #787d86;
    text-align: center;
    font-weight: 600;
    margin: 0 auto;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem auto;
    min-height: 65vh;
    margin-bottom: 3rem;
    margin: 1rem;
    background-color: #e6eff5;
    background-repeat: no-repeat;
    background-position: center center;

    .site {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      background: #005994;
      border-bottom: 3px solid #e33c90;
      border-radius: 20px;
      min-height: 200px;
      padding: 1rem;

      &__title {
        font-weight: 700;
        font-size: 38px;
        line-height: 49px;
        text-align: center;
        color: #ffffff;
        margin-top: 1rem;
      }

      &__desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #ececec;
        margin-top: 0.5rem;
      }

      .details {
        font-weight: 500;
        font-size: 0.9rem;
        margin: 0.175rem 0;
        text-align: center;
        text-decoration-line: underline;
        align-self: flex-end;
        cursor: pointer;
      }
    }

    .btn {
      min-width: 250px;
      min-height: 45px;
      font-size: 1.1rem;
      text-transform: none;
      border-radius: 10px;
      padding: 5px;
      color: #fff;
      background-color: #005994;
    }
  }
}

@media screen and (max-width: 1200px) {
  .licenseSetailsPage__Container {
    min-height: 55vh;
    height: max-content !important;
  }
}

@media screen and (max-width: 1100px) {
  .licenseDetailsPage {
    flex-direction: column;
  }
}

@media screen and (max-width: 520px) {
  .licenseDetailsPage {
    padding: 0 1rem;
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
    &__container {
      padding: 2rem 1rem;

      .gridContainer {
        max-height: max-content;
      }
    }
  }
}

@media screen and (max-width: 445px) {
  .licenseDetailsPage {
    &__imageContainer .img {
      width: 350px;
    }
  }
}
