.itemContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.75rem;
  margin: 1.75rem 0;

  .itemTitle {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: start;
    color: #e33c90;
    // display: flex;
    // align-items: center;
    font-family: "Roboto";

    span {
      display: inline-block;
    }
  }

  .itemContent {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    width: 100%;

    .input {
      border: 1px solid #ccc;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      max-width: 300px;
      width: 100%;
      border: 2px solid transparent;
    }

    .new-input {
      // padding: 0.5rem 1rem;
      // border-radius: 4px;
      // max-width: 300px;
      // width: 100%;
      // border: 2px solid transparent;
      background-color: #fff;
    }
    .select {
      border: none;
      background-color: #fff;
      padding: 0.5rem 1rem;
      outline: none;
      max-width: 300px !important;
      width: 100%;
    }

    .textHelper {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #7a7a7a;
    }
  }
}

@media screen and (max-width: 1000px) {
  .itemContainer .itemContent {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .itemContainer .itemTitle {
    font-size: 24px;
    text-align: start;
  }
}
