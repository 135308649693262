.resourcesPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  padding-bottom: 1.5rem;
  flex: 1;
  padding-bottom: 4rem;

  .resources__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 52px;
    text-align: start;
    color: #5c5c5c;
  }

  .resources__subtitle {
    font-weight: 500;
    font-size: 28px;
    line-height: 52px;
    text-align: start;
    color: #5c5c5c;
    margin-top: 3rem;
  }

  .resources__tutorials {
    display: flex;
    align-items: start;
    justify-content: space-around;
    gap: 1rem;

    .tutorial {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 2.5rem;
      width: 100%;

      .tutorialVideo {
        border-radius: 18px;
      }

      .tutorialText {
        font-weight: 600;
        font-size: 25px;
        line-height: 29px;
        text-transform: none;
        color: #696767;
      }
    }
  }

  .resources__goals {
    margin-top: 3rem;

    .goal {
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 1rem;
      // margin: 1rem;
      min-height: 450px;
      max-width: 600px;
      // width: calc(100% - 32px);
      height: 100%;
      margin-left: auto;
      margin-right: auto;

      .goalImg {
        width: 100%;
        object-fit: cover;
        height: 320px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }

      .goalContent {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin-top: 1.5rem;
        flex: 1;
        width: 100%;

        .content__title {
          font-weight: 700;
          font-size: 25px;
          line-height: 140%;
          text-transform: none;
          color: #e33c90;
          text-align: center;
          flex: 1;
        }

        .content__desc {
          font-weight: 400;
          font-size: 16px;
          line-height: 27px;
          color: #798196;
          flex: 1;
        }

        .tipsContainer {
          width: 100%;
          border: 1px solid;
          border-radius: 10px;
          padding: 1rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          row-gap: 0.5rem;

          .tip {
            font-weight: 400;
            font-size: 16px;
            line-height: 104.69%;
            text-transform: capitalize;
            color: #787d86;
            margin: 0.75rem;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin: 1rem 0;
    margin-bottom: 3rem;

    .subHeader {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .subMain {
      display: flex;
      margin-top: 1rem;
      flex: 1;
      align-items: center;

      .introContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 1.5rem;
        width: 100%;

        .teethIcon {
          width: 150px;
          height: auto;
          object-fit: contain;
        }
        .intro {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 8rem;
          width: 100%;

          &__title {
            font-weight: 700;
            font-size: 62px;
            line-height: 72px;
            text-align: right;
            letter-spacing: -0.2px;
            color: #e33c90;
            margin: 0 4rem;
          }

          &__username {
            font-weight: 700;
            font-size: 32px;
            line-height: 24px;
            text-align: right;
            color: #005994;
            margin: 0 4rem;
          }

          .textContainer {
            max-width: 900px;
            background: #ffffff;
            border: 1px solid #000000;
            border-radius: 24px;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            row-gap: 1.5rem;
            align-items: flex-start;
            margin: 0 3rem;

            .text {
              font-weight: 500;
              font-size: 26px;
              line-height: 30px;
              color: rgb(103, 103, 103, 0.8);
              margin: 0.5rem 0;
            }

            .btn {
              min-width: 300px;
              min-height: 50px;
              color: #fff;
              font-size: 1.1rem;
              font-weight: 700;
              background: #005994;
              border-radius: 10px;
              padding-left: 2rem;
              padding-right: 2rem;
              margin-top: 1rem;
              text-transform: none;
              align-self: flex-end;
            }
          }
        }
      }
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 945px) {
  .resourcesPage .resources__tutorials {
    flex-direction: column;
  }
}

@media screen and (max-width: 445px) {
  .ResourcesPage {
    &__imageContainer .img {
      width: 350px;
    }
  }
}
