.languageSupport {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 4rem;
  max-width: 1536px;

  &__backArrow {
    cursor: pointer;
    margin-right: 2rem;
    .backIcon {
      font-size: 2.1rem;
      color: #333333;

      &:hover {
        color: #005994;
      }
    }
  }
  &__language {
    display: flex;
    align-items: center;
    column-gap: 4px;
    row-gap: 1rem;
    margin-left: auto;

    .language {
      color: #9ea6b3;
      font-size: 1.1rem;
      cursor: pointer;
    }
    .language--selected {
      color: #005994;
    }
  }
}

@media screen and (max-width: 645px) {
  .languageSupport {
    padding: 1rem;
    flex-direction: column-reverse;
    align-items: self-start;
    row-gap: 1rem;

    &__language {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }
}
