.actionPlanningPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin: 1rem 0;
    margin-bottom: 3rem;

    .subHeader {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .subMain {
      display: flex;
      margin-top: 1rem;
      flex: 1;
      align-items: center;

      .introContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 1.5rem;
        width: 100%;

        .introTitle {
          font-weight: 700;
          text-align: right;
          letter-spacing: -0.2px;
          color: #e33c90;
          margin: 0 4rem;
        }

        .introUsername {
          font-weight: 700;
          font-size: 32px;
          line-height: 24px;
          text-align: right;
          color: #005994;
          margin: 0 4rem;
        }
        .intro {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 8rem;
          width: 100%;
          margin-top: 2rem;

          .teethIcon {
            width: 150px;
            height: auto;
            object-fit: contain;
          }

          .textContainer {
            min-height: 250px;
            max-width: 900px;
            background: #ffffff;
            border: 1px solid #000000;
            border-radius: 24px;
            padding: 2rem 1.5rem;
            display: flex;
            flex-direction: column;
            row-gap: 1.5rem;
            align-items: flex-start;
            justify-content: center;
            margin: 0 3rem;

            .text {
              font-weight: 500;
              color: rgb(103, 103, 103, 0.8);
            }
          }
        }
      }
      .actionContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        row-gap: 1.5rem;

        .actionHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .goal {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #e33c90;
            border: 3px solid #e33c90;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 14px;
            padding: 0.5rem 1.5rem;
            max-width: 500px;
            width: 100%;

            .goalTitle {
              font-weight: 700;
              font-size: 1.5rem;
              line-height: 35px;
              text-align: center;
              color: #ffffff;
            }
          }

          .username {
            font-weight: 700;
            font-size: 1.75rem;
            line-height: 24px;
            text-align: right;
            color: #005994;
            margin: 0 1.5rem;
          }
        }
        .actionContentContainer {
          .actionContent {
            max-width: 850px;
          }
        }
      }

      .btnContainer {
        display: flex;
        justify-content: flex-end;
        column-gap: 1.5rem;
        width: 100%;

        .btn {
          min-width: 200px;
          max-width: 300px;
          width: 100%;
          min-height: 50px;
          font-size: 1.1rem;
          font-weight: 700;
          border-radius: 10px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 1.5rem;
          text-transform: none;
          align-self: flex-end;
        }

        .continueBtn {
          background: #005994;
          color: #fff;
        }

        .backBtn {
          background: #ffffff;
          border: 3px solid #005994;
          color: #005994;
        }
      }
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1100px) {
  .actionPlanningPage__contentContainer .subMain {
    .introContainer {
      .intro {
        flex-direction: column;
        row-gap: 2rem;

        .textContainer {
          margin: 0;
        }
      }
      .introTitle,
      .introUsername {
        margin-right: 1rem;
      }
    }
    .btnContainer {
      margin: 0;
    }
  }
}

@media screen and (max-width: 800px) {
  .actionPlanningPage__contentContainer {
    .subMain .btnContainer {
      flex-direction: column;
      align-items: center;

      .btn {
        align-self: auto;
      }
    }

    .subMain .actionContainer .actionHeader {
      flex-direction: column;
      row-gap: 2rem;
      align-items: flex-start;

      .username {
        align-self: flex-end;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .actionPlanningPage {
    padding: 0 1rem;
    &__contentContainer {
      padding: 2rem 1rem;
    }

    &__contentContainer .subMain .introContainer .intro .textContainer .text {
      font-size: 20px;
    }
  }
}
