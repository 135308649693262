.overviewPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 4rem;
  margin: 2rem 0;
  width: 80%;

  &__progressSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
    column-gap: 3rem;
    width: 100%;

    .logo {
      margin: 1rem 0;
    }

    .progressContainer {
      padding: 1.5rem 2rem;
      border-radius: 20px;
      border: 1px solid #787d86;
      width: 100%;
    }
  }

  &__information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .overviewPage {
    flex-direction: column;
  }
}
@media screen and (max-width: 575px) {
  .overviewPage__progressSection {
    flex-direction: column;
  }
}
