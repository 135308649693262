.userFollowUpPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  margin-top: 1rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #e6eff5;

    background-repeat: no-repeat;
    background-position: center center;
    min-height: 65vh;
    width: 100%;
    margin-bottom: 3rem;
    margin: 1rem 0;

    .subHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;
      background: #005994;
      border-radius: 10px 10px 0px 0px;
    }

    .subMain {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      flex: 1;
      height: 100%;
      padding: 2rem;
    }
  }
}

@media screen and (max-width: 1140px) {
  .memberDetailsuserFollowUpPage__contentContainer .subMain__goalsContainer {
    flex-direction: column-reverse;
  }
  .memberDetailsuserFollowUpPage__contentContainer .subHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;

    .btnContainer {
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 885px) {
  .memberDetailsuserFollowUpPage__contentContainer .subHeader {
    .btnContainer {
      flex-direction: column;
      align-self: flex-end;
      row-gap: 1rem;
    }
  }
}
@media screen and (max-width: 775px) {
  .memberDetailsuserFollowUpPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .memberDetailsuserFollowUpPage__contentContainer {
    .subMain__memberInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-left: 1rem;
    }
  }
}
@media screen and (max-width: 520px) {
  .memberDetailsuserFollowUpPage {
    padding: 0;
    &__contentContainer {
      padding: 2rem 1rem;
    }
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
  }
}
@media screen and (max-width: 475px) {
  .memberDetailsuserFollowUpPage__contentContainer {
    .subMain__goalsContainer .btnContainer {
      .redoRiskBtn,
      .showRiskBtn {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .userFollowUpPage__contentContainer .subMain {
    flex-direction: column-reverse;
    row-gap: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .userFollowUpPage {
    padding: 0 1rem;
  }
  .userFollowUpPage__contentContainer .subMain {
    padding: 1rem;
    padding-bottom: 2rem;
  }
}
