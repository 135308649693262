.dialog {
  .dialogTitle {
    background-color: #005994;
    color: #fff;
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    row-gap: 1.2rem;
    align-items: center;
    margin: 0 auto;
    margin-top: 1rem;

    .formControl {
      display: flex;
      column-gap: 1rem;
      align-items: center;
      width: 100%;

      .input {
        // border: 1px solid #ccc;
        // padding: 0.5rem 1rem;
        // border-radius: 4px;
        max-width: 300px;
        width: 100%;
      }
    }
  }
  .dialogContent {
    padding: 1rem;
    padding-top: 1.5rem !important;
  }
  .dialogActions {
    padding: 0.5rem 2rem;
    padding-bottom: 1.5rem;
    column-gap: 1.5rem;

    .saveBtn,
    .cancelBtn {
      width: 170px;
      height: 50px;
      border-radius: 10px;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      text-transform: none;
      margin-left: 0;
    }

    .saveBtn {
      color: #fff;
    }
    .saveBtn:not(:disabled) {
      background-color: #005994;
    }

    .cancelBtn {
      background: #ffffff;
      color: #005994;
      border: 1px solid #787d86;
    }
  }
}

@media screen and (max-width: 580px) {
  .dialog .formContainer .formControl {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
  }
}
@media screen and (max-width: 480px) {
  .dialog .dialogActions {
    flex-direction: column;
    row-gap: 0.5rem;
  }
}
