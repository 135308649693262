.pcRelationInfoPage {
  width: 65%;
  flex: 1;

  &__title {
    color: #787d86;
    text-align: center;
    max-width: 1000px;
  }

  &__formContainer {
    margin-top: 2rem;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__formControl {
        width: 60%;
        margin-bottom: 3rem;

        .label {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          align-self: center;
        }
      }
    }
    .saveBtn {
      background-color: #005994;
      color: #fff;
      margin: 1rem 0;
      border-radius: 18px;
      height: 60px;
      font-size: 1.1rem;
      align-self: center;
      width: 300px;
    }
    .skipBtn {
      background-color: #fff;
      color: #005994;
      border: 4px solid #005994;
      margin: 1rem 0;
      border-radius: 18px;
      height: 60px;
      font-size: 1.1rem;
      align-self: center;
      width: 270px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .pcRelationInfoPage {
    width: 70%;
    .form {
      &__formControl {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .pcRelationInfoPage {
    width: 90%;
  }
}
@media screen and (max-width: 775px) {
  .pcRelationInfoPage {
    width: 100%;
    .form {
      &__formControl {
        width: 90%;
      }
    }
  }
}
