.footer__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #ccdeea;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}

.footer__content {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.14994px;
  color: #000000;
  opacity: 0.8;

  a {
    text-decoration: underline;
  }
}
