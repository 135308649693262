.cavityTutorialPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
    color: #676767;
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin: 1rem 0;
    margin-bottom: 3rem;
    flex: 1;

    .subMain {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1rem;
      flex: 1;
      align-items: center;
      row-gap: 2rem;

      .contentContainer {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        row-gap: 2rem;
        padding: 1rem;
        height: 100%;

        .title {
          font-weight: 700;
          // font-size: 40px;
          line-height: 54px;
          text-align: right;
          letter-spacing: -0.2px;
          color: #e33c90;
        }

        .user {
          font-weight: 700;
          font-size: 1.75rem;
          line-height: 24px;
          text-align: right;
          color: #005994;
        }

        .content {
          background: linear-gradient(180deg, #ffffff 99.99%, #eddbff 100%);
          border: 2px solid #9ea6b3;
          border-radius: 16px;
          padding: 1rem 1.75rem;
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          margin-top: 2rem;

          .teethIcon {
            width: 80px;
            height: 80px;
            object-fit: contain;
            position: absolute;
            bottom: -50px;
            left: -20px;
          }

          .contentText {
            font-size: 25px;
            line-height: 28px;
            text-align: center;
            color: #676767;
          }
        }
        .btn {
          max-width: 300px;
          width: 100%;
          min-height: 50px;
          color: #fff;
          font-size: 1.1rem;
          font-weight: 700;
          background: #005994;
          border-radius: 10px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 3rem;
          text-transform: none;
        }
      }

      .video {
        iframe {
          border-radius: 18px;
        }
      }

      .btn {
        min-width: 300px;
        min-height: 50px;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 700;
        background: #005994;
        border-radius: 10px;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 3rem;
        text-transform: none;
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .cavityTutorialPage__contentContainer .subMain__goalsContainer {
    flex-direction: column-reverse;
  }
  .cavityTutorialPage__contentContainer .subHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;

    .btnContainer {
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 885px) {
  .cavityTutorialPage__contentContainer .subHeader {
    .btnContainer {
      flex-direction: column;
      align-self: flex-end;
      row-gap: 1rem;
    }
  }
}
@media screen and (max-width: 775px) {
  .cavityTutorialPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .cavityTutorialPage__contentContainer {
    .subMain__memberInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-left: 1rem;
    }
  }
}
@media screen and (max-width: 520px) {
  .cavityTutorialPage {
    padding: 0;
    &__contentContainer {
      padding: 2rem 1rem;
    }
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
  }
}
@media screen and (max-width: 475px) {
  .cavityTutorialPage__contentContainer {
    .subMain__goalsContainer .btnContainer {
      .redoRiskBtn,
      .showRiskBtn {
        width: 100%;
      }
    }
  }
}
