.startGoalPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin: 1rem 0;
    margin-bottom: 3rem;

    .subHeader {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .hintContainer {
      display: flex;
      align-items: center;
      column-gap: 2.5rem;
      margin: 0 2rem;

      .teethIcon {
        width: 80px;
        height: auto;
        object-fit: contain;
      }

      .hint {
        background: #ffffff;
        border: 1px solid #787d86;
        border-radius: 20px;
        padding: 1.5rem;
        width: 100%;
        min-height: 160px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .hint__text {
          font-weight: 400;
          font-size: 1.1rem;
          line-height: 26px;
          text-align: start;
          color: #646870;
        }
      }
    }

    .subMain {
      display: flex;
      margin-top: 1rem;
      flex: 1;
      align-items: center;

      .startGoalContainer {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        width: 100%;

        .title {
          font-weight: 700;
          font-size: 2.75rem;
          line-height: 72px;
          color: #e33c90;
        }

        .btnContainer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 1.5rem;
          margin-top: 3rem;

          .btn {
            min-width: 200px;
            max-width: 300px;
            width: 100%;
            min-height: 50px;
            color: #fff;
            font-size: 1.1rem;
            font-weight: 700;
            border-radius: 10px;
            padding-left: 2rem;
            padding-right: 2rem;
            text-transform: none;
            align-self: flex-end;
            margin-top: 1.5rem;
          }
          .nextBtn {
            background: #005994;
          }
          .backBtn {
            background: #e33c90;
          }
        }
      }

      .goal {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e33c90;
        border: 3px solid #e33c90;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 14px;
        padding: 0.5rem 1.5rem;
        max-width: 500px;
        width: 100%;

        .goalTitle {
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 35px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .startGoalPage__contentContainer {
    .hintContainer {
      flex-direction: column;
      row-gap: 2rem;
    }
  }
}

@media screen and (max-width: 700px) {
  .startGoalPage__contentContainer {
    .hintContainer {
      margin: 0;
      margin-top: 2rem;
    }
    .subMain .btnContainer {
      flex-direction: column;

      .btn {
        align-self: center !important;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .startGoalPage {
    padding: 0 1rem;
    &__contentContainer {
      padding: 2rem 1rem;
    }
  }
}
