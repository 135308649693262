.firstIntroContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 1.5rem;
  width: 100%;

  .teethIcon {
    width: 150px;
    height: auto;
    object-fit: contain;
  }

  .intro__title {
    font-weight: 700;
    text-align: right;
    letter-spacing: -0.2px;
    color: #e33c90;
    margin-right: 4rem;
  }

  .intro__username {
    font-weight: 700;
    font-size: 32px;
    line-height: 24px;
    text-align: right;
    color: #005994;
    margin-right: 4rem;
  }

  .textContainer {
    min-height: 300px;
    max-width: 850px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 24px;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 2rem;

    .text {
      font-weight: 500;
      color: rgb(103, 103, 103, 0.8);
    }

    .btn {
      min-width: 200px;
      max-width: 300px;
      width: 100%;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 700;
      background: #005994;
      border-radius: 10px;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 1.5rem;
      text-transform: none;
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 1100px) {
  .firstIntroContainer {
    .intro {
      flex-direction: column;
      row-gap: 2rem;

      .textContainer {
        margin: 0;
      }
    }
    .intro__title,
    .intro__username {
      margin-right: 1rem;
    }
  }
}
@media screen and (max-width: 520px) {
  .firstIntroContainer .intro .textContainer .text {
    font-size: 20px;
  }
}
