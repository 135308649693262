.customSupportText {
  .customText {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 35px;
    color: #646870;
  }

  .customList {
    padding-left: 2rem;
    list-style-type: disc;
    font-size: 1.5rem;
    padding-top: 0;
  }
}
