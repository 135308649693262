.introduction {
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  h3 {
    font-weight: 400;
    font-size: 38px;
    line-height: 50.83px;
  }

  .btn {
    min-width: 300px;
    min-height: 50px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    background: #005994;
    border-radius: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: auto;
    text-transform: none;
  }
}

.timeAndType {
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  h3 {
    font-weight: 400;
    font-size: 34px;
    line-height: 50.83px;
    color: #000000;
  }

  .foodDrinkBtn {
    min-width: 150px;
    height: 50px;
    background: #ffffff;
    padding: 0.5rem 1rem;
    border: 2px solid #005994;
    border-radius: 0;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #005994;
    text-transform: none;
  }

  .foodDrinkBtn--selected {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    background-color: #005994;
    color: #fff;
  }

  .btn {
    min-width: 300px;
    min-height: 50px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    background: #005994;
    border-radius: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
    text-transform: none;
  }
}

.foodAndDrinks {
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  h3,
  h5 {
    font-weight: 400;
    font-size: 34px;
    line-height: 50.83px;
    color: #000000;
  }

  h5 {
    font-size: 24px;
    line-height: 30px;
  }

  .foodDrinkBtn {
    min-width: 150px;
    height: 50px;
    background: #ffffff;
    padding: 0.5rem 1rem;
    border: 2px solid #005994;
    border-radius: 0;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #005994;
    text-transform: none;
  }

  .foodDrinkBtn--selected {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    background-color: #005994;
    color: #fff;
  }

  .selected-food-drinks-category {
    border: 3px solid #9ea6b2;
    border-radius: 18px;
    height: 212px;
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    // margin-top: 1rem;
    margin-top: auto;

    p {
      color: #364e9a;
      font-size: 20px;
      padding: 0 1rem;
      text-align: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .add-btn {
    width: fit-content;
    min-height: 42px;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    background: #005994;
    border-radius: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: none;
    height: fit-content;
  }

  .secondary-btn {
    width: 100%;
    min-height: 50px;
    color: #364e9a;
    font-size: 1.1rem;
    font-weight: 700;
    background: #fff;
    border-radius: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: none;
    height: fit-content;
    max-width: 250px;
    border: 3px solid #364e9a;
    margin-top: 4rem;

    &:hover {
      color: #364e9a !important;
      background: #fff !important;
      border: 3px solid #364e9a !important;
    }
  }
  .btn {
    min-width: 300px;
    min-height: 50px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    background: #005994;
    border-radius: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
    text-transform: none;
  }
}
.summary {
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  h2 {
    font-weight: 400;
    font-size: 40px;
    color: #000000;
  }

  h4 {
    color: #005994;
    font-weight: 400;
    font-size: 24px;
  }

  h3,
  h5 {
    font-weight: 400;
    font-size: 34px;
    line-height: 50.83px;
    color: #000000;
  }

  h5 {
    font-size: 24px;
    line-height: 30px;
  }

  .foodDrinkBtn {
    min-width: 150px;
    height: 50px;
    background: #ffffff;
    padding: 0.5rem 1rem;
    border: 2px solid #005994;
    border-radius: 0;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #005994;
    text-transform: none;
  }

  .foodDrinkBtn--selected {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    background-color: #005994;
    color: #fff;
  }

  .selected-food-drinks-category {
    border: 3px solid #9ea6b2;
    border-radius: 18px;
    height: 212px;
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: auto;

    p {
      color: #364e9a;
      font-size: 20px;
      padding: 0 1rem;
      text-align: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .add-btn {
    width: fit-content;
    min-height: 42px;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    background: #005994;
    border-radius: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: none;
    height: fit-content;
  }

  .secondary-btn {
    width: 100%;
    min-height: 50px;
    color: #364e9a;
    font-size: 1.1rem;
    font-weight: 700;
    background: #fff;
    border-radius: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: none;
    height: fit-content;
    max-width: 250px;
    border: 3px solid #364e9a;
    margin-top: 4rem;

    &:hover {
      color: #364e9a !important;
      background: #fff !important;
      border: 3px solid #364e9a !important;
    }
  }
  .btn {
    max-width: 300px;
    width: 100%;
    min-height: 50px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    background: #005994;
    border-radius: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
    text-transform: none;
  }
}

@media screen and (max-width: 768px) {
  .introduction {
    min-height: 600px;
    padding: 3rem 1rem 2rem;
    h3 {
      font-size: 34px;
    }

    .btn {
      margin-top: auto;
    }
  }
}
