.verify-code-input {
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  width: 300px;
}

.verify-code-input::-webkit-outer-spin-button,
.verify-code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.verify-code-input[type="number"] {
  -moz-appearance: textfield;
}

.followUpInfoPage {
  margin-top: 4rem;
  flex: 1;

  &__title {
    color: #787d86;
    text-align: center;
    max-width: 1000px;
  }
  &__content {
    direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;

    .followUpContainerChoices {
      display: flex;
      align-items: stretch;
      justify-content: space-around;
      cursor: pointer;

      .followUpBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 1rem;
        background: #ffffff;
        border: 3px solid #9ea6b3;
        box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
        border-radius: 18px;
        width: 234px;
        min-height: 204px;
        margin: 1rem 0;
      }

      .followUpBox--selected {
        background-color: #005994;
        color: #fff !important;
        border: 3px solid #9ea6b2;
      }
      .whatsLogo,
      .emailLogo {
        width: 120px;
        height: 90px;
        object-fit: contain;
      }

      .smallWhatsLogo,
      .smallEmailLogo {
        width: 75px;
        height: 68px;
        object-fit: contain;
      }

      .textName {
        color: #2e7aff;
        font-size: 1.75rem;
        font-weight: 700;
        margin-top: 1rem;
      }

      .textName--selected {
        color: #fff;
      }
    }

    .followUpContainerConfirm {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      column-gap: 10%;

      .btn {
        background: #005994;
        color: #fff;
        border: 1px solid #f7c5c5;
        border-radius: 18px;
        min-height: 60px;
        margin: 1rem;
        margin-top: 60px;
      }
    }

    .phoneContainerClass {
      max-width: 400px;
      height: 55px;
      margin-top: 1rem;
    }
    .phoneInputClass {
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      margin: 1rem 0;
    }
    .phoneButtonClass {
      display: flex;
      justify-content: center;
      width: 0;
      display: none;
    }

    .phoneContainer {
      display: flex;
      padding: 1rem 1rem 0;
      gap: 1rem;

      .flagContainer {
        display: flex;
        align-items: center;
        border: 1px solid #cacaca;
        height: 55px;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        border-radius: 5px;
        margin: 1rem 0;

        .phoneFlag {
          width: 40px;
          height: auto;
          object-fit: contain;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1032px) {
  .followUpInfoPage__content {
    .followUpContainerConfirm {
      column-gap: 2rem;
    }
  }
}

@media screen and (max-width: 920px) {
  .followUpInfoPage__content {
    .followUpContainerConfirm {
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
      column-gap: 3rem;

      .btn {
        align-self: center;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .followUpInfoPage__content {
    .followUpContainerConfirm {
      column-gap: 2rem;
    }
  }
}
