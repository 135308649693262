.assessmentPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin: 1rem;
    margin-bottom: 3rem;

    .subHeader {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-bottom: 3px solid #666666;



      .stepper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
      }

      .stepper--secondary {
        display: none;
      }

      .MuiStepLabel-iconContainer {
        display: none !important;
      }

      .MuiStepLabel-root {
        min-width: 150px !important;
        width: 100%;
        min-height: 50px !important;
        border: 4px solid #666666 !important;
        text-align: center;
        padding: 0.5rem;
        border-radius: 8px;
        text-transform: uppercase;
      }
      .MuiStepConnector-line {
        border: 2px solid #666666 !important;
      }

      .step {
        span {
          font-size: 1.2rem;
        }
      }

      .step--active {
        border: 4px solid #005994 !important;
        min-width: 150px !important;
        width: 100%;
        min-height: 50px !important;

        span {
          color: #005994 !important;
          font-size: 1.2rem;
        }
      }
      .step--previous {
        background: #005994 !important;
        border: 4px solid #005994 !important;
        min-width: 150px !important;
        min-height: 50px !important;
        width: 100%;

        span {
          color: #fff !important;
          font-size: 1.2rem;
        }
      }
    }

    .hideBorderBottom{
      border-bottom: 0;
    }

    .subMain {
      display: flex;
      flex: 1;
      align-items: center;

      &__memberInfo {
        display: flex;
        column-gap: 7rem;
        margin: 1rem 0;
      }
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1140px) {
  .assessmentPage__contentContainer .subHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;

    .btnContainer {
      align-self: flex-end;
    }
  }
}
@media screen and (max-width: 1200px) {
  .assessmentPage__contentContainer {
    .subHeader {
      border-bottom: none;
      .stepper--main {
        display: none;
      }

      .stepper--secondary {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 885px) {
  .assessmentPage__contentContainer .subHeader {
    .btnContainer {
      flex-direction: column;
      align-self: flex-end;
      row-gap: 1rem;
    }
  }
}
@media screen and (max-width: 775px) {
  .assessmentPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .assessmentPage__contentContainer {
    .subMain__memberInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-left: 1rem;
    }
  }
}
@media screen and (max-width: 520px) {
  .assessmentPage {
    padding: 1rem;
    &__contentContainer {
      padding: 2rem 1rem;
    }
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
  }
}

.box_stepperAndLanguage {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px !important;
}

.tabTimeColor {
  background: #2e7aff !important;
  color: #ffffff !important;
  border: 3px solid #2e7aff !important;
}

.imageBoxColor {
  border: 3px solid #2e7aff !important;
}

.typo_stepper {
  font-family: 'Roboto', sans-serif !important ;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 44px !important;
  line-height: 52px !important;
  text-align: center !important;

  color: #787d86 !important;
  margin-right: 15px !important;
}

.typo_question_instruction {
  color: #2f2e41 !important;
  font-size: 17px !important;
  text-align: center !important;
  margin-top: 13px !important;
}

.typo_breadAbu {
  color: #2e7aff !important;
}

.CardStepper {
  width: 100% !important;
  /* height: 80vh !important; */
  background-color: #ffffff !important;
}

.tabPanel {
  padding-right: 30px;
  padding-bottom: 10px !important;
}
// .MuiTabs-scrollButtons {
//   //display: none !important;
// }

.tabChange {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23px !important;
  text-align: center !important;
  color: #888888 !important;
  width: 192px !important;
  height: 56.95px;
  background: #ffffff;
  border: 3px solid #9ea6b3 !important;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25),
    inset 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px !important;
  /* padding-right: 20px !important; */
  margin-bottom: 14px !important;
}

.box_tabPanelMainAll {
  width: 87%;
  height: 69vh;
  /* margin-left: 40px !important; */
  /* padding-right: 40px; */
  overflow-y: auto;
  scrollbar-gutter: stable;
}
.box_tabPanelMainAll::-webkit-scrollbar {
  width: 13px !important;
}
/* Track */
.box_tabPanelMainAll::-webkit-scrollbar-track {
  box-shadow: inset -3px -3px 6px rgba(255, 255, 255, 0.5),
    inset 3px 3px 6px rgba(12, 0, 58, 0.1) !important;
  border-radius: 3px !important;
}
/* Handle */
.box_tabPanelMainAll::-webkit-scrollbar-thumb {
  background: #d8d8d8 !important;

  border-radius: 20px !important;
}

.text45 {
  padding-right: 30px !important;
  padding-bottom: 30px !important;
  margin-top: 30px !important;
  /* overflow-y: auto;
    scrollbar-gutter: stable; */
}

.box_btnFoodDrinks {
  margin-bottom: 20px !important;
  text-align: center !important;
}

.typo_questionDiet {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 34px !important;
  line-height: 32px !important;
  /* identical to box height */

  text-align: center !important;

  color: #676767 !important;
}

.box_mainImage {
  display: flex;
  flex-wrap: wrap;

  width: 100% !important;
}

.box1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 159px !important;
  height: 159px;
  /* margin-left: 42px !important; */
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25),
    inset 0px 3px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 18px;
  margin-bottom: 32px !important;
}

// .MuiTabs-indicator {
//   display: none !important;
// }

/* stepper  */
.box_stepper {
  padding: 10px 114px 0px 96px !important;
}

.MuiStep-root {
  padding: 0px !important;
}
