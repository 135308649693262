.customModal {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: calc(100% - 32px);
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 6px;
  padding: 1.5rem 3rem;

  &__img {
    width: 70px;
    height: 80px;
    object-fit: contain;
  }

  .btn {
    background-color: #005994;
    color: #fff;
    width: 200px;
  }
}

@media screen and (max-width: 900px) {
  .customModal {
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
  }
}
