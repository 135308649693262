.audioSupport {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 1.5rem;
  max-width: 1536px;

  .audio {
    display: flex;
    align-items: center;
    cursor: pointer;

    .audioContainer {
      margin-right: 0.5rem;
      background-color: rgb(125, 75, 44);
      font-size: 1.5rem;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .audioIcon {
      color: #fff;
      font-size: 16px;
    }

    .audioText {
      font-weight: 700;
      font-size: 20px;
      line-height: 33px;
      text-align: center;
      color: #8f1351;
    }
  }
}

@media screen and (max-width: 645px) {
  .audioSupport {
    padding: 1rem;
    flex-direction: column-reverse;
    align-items: self-start;
    row-gap: 2rem;
  }
}
