.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.cultural__dropdown-menu .MuiMenu-paper {
  min-width: 200px !important;
}

.dropdown__menu .MuiMenu-paper {
  min-width: 250px !important;
}
