.goalSettingsPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin: 1rem;
    margin-bottom: 3rem;

    .subHeader {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .subMain {
      display: flex;
      margin-top: 1rem;
      flex: 1;
      align-items: center;

      .introContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 1.5rem;
        width: 100%;

        .teethIcon {
          width: 150px;
          height: auto;
          object-fit: contain;
        }
        .intro {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 8rem;
          width: 100%;

          &__title {
            font-weight: 700;
            font-size: 62px;
            line-height: 72px;
            text-align: right;
            letter-spacing: -0.2px;
            color: #e33c90;
            margin: 0 4rem;
          }

          &__username {
            font-weight: 700;
            font-size: 32px;
            line-height: 24px;
            text-align: right;
            color: #005994;
            margin: 0 4rem;
          }

          .textContainer {
            max-width: 900px;
            background: #ffffff;
            border: 1px solid #000000;
            border-radius: 24px;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            row-gap: 1.5rem;
            align-items: flex-start;
            margin: 0 3rem;

            .text {
              font-weight: 500;
              font-size: 26px;
              line-height: 30px;
              color: rgb(103, 103, 103, 0.8);
              margin: 0.5rem 0;
            }

            .btn {
              min-width: 300px;
              min-height: 50px;
              color: #fff;
              font-size: 1.1rem;
              font-weight: 700;
              background: #005994;
              border-radius: 10px;
              padding-left: 2rem;
              padding-right: 2rem;
              margin-top: 1rem;
              text-transform: none;
              align-self: flex-end;
            }
          }
        }
      }
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1140px) {
  .goalSettingsPage__contentContainer .subMain__goalsContainer {
    flex-direction: column-reverse;
  }
  .goalSettingsPage__contentContainer .subHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;

    .btnContainer {
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 885px) {
  .goalSettingsPage__contentContainer .subHeader {
    .btnContainer {
      flex-direction: column;
      align-self: flex-end;
      row-gap: 1rem;
    }
  }
}
@media screen and (max-width: 775px) {
  .goalSettingsPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .goalSettingsPage__contentContainer {
    .subMain__memberInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-left: 1rem;
    }
  }
}
@media screen and (max-width: 520px) {
  .goalSettingsPage {
    padding: 0 1rem;
    &__contentContainer {
      padding: 2rem 1rem;
    }
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
  }
}
@media screen and (max-width: 475px) {
  .goalSettingsPage__contentContainer {
    .subMain__goalsContainer .btnContainer {
      .redoRiskBtn,
      .showRiskBtn {
        width: 100%;
      }
    }
  }
}

.box_stepperAndLanguage {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px !important;
}

.typo_stepper {
  font-family: "Roboto", sans-serif !important ;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 44px !important;
  line-height: 52px !important;
  text-align: center !important;

  color: #787d86 !important;
  margin-right: 15px !important;
}

.typo_question_instruction {
  color: #2f2e41 !important;
  font-size: 17px !important;
  text-align: center !important;
  margin-top: 13px !important;
}

.typo_breadAbu {
  color: #2e7aff !important;
}

.CardStepper {
  width: 100% !important;
  /* height: 80vh !important; */
  background-color: #ffffff !important;
}

.tabPanel {
  padding-right: 30px;
  padding-bottom: 10px !important;
}

.box_btnFoodDrinks {
  margin-bottom: 20px !important;
  text-align: center !important;
}

.typo_questionDiet {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 34px !important;
  line-height: 32px !important;
  /* identical to box height */

  text-align: center !important;

  color: #676767 !important;
}

.box_mainImage {
  display: flex;
  flex-wrap: wrap;

  width: 100% !important;
}

.box1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 159px !important;
  height: 159px;
  /* margin-left: 42px !important; */
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25),
    inset 0px 3px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 18px;
  margin-bottom: 32px !important;
}

.tabTimeColor {
  background: #2e7aff !important;
  color: #ffffff !important;
  border: 3px solid #2e7aff !important;
}

.imageBoxColor {
  border: 3px solid #2e7aff !important;
}

.MuiTabs-indicator {
  display: none !important;
}

/* stepper  */
.box_stepper {
  padding: 10px 114px 0px 96px !important;
}

.MuiStep-root {
  padding: 0px !important;
}

@import "first-intro.scss";
@import "second-intro.scss";
@import "goal-safe-areas.scss";
@import "goal-risk-areas.scss";
