.editSiteDetailsPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .familyLogoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem 1rem;
    }

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__membersContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem;
    background-color: #e6eff5;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin-bottom: 3rem;
    flex: 1;

    .subHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btnContainer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-self: flex-end;
      }

      &__desc {
        font-weight: 350;
        font-size: 18px;
        line-height: 26px;
        color: #333333;
        margin: 0 auto;
      }

      .btn {
        border-radius: 8px;
        font-weight: 700;
        font-size: 1.2rem;
        text-transform: none;
        padding: 0.5rem 1.5rem;
        min-width: 200px;
        max-width: 270px;
        width: 100%;
        margin: 0 0.75rem;
      }

      .containedBtn {
        background: #005994;
        color: #fff;
      }

      .outlinedBtn {
        color: #005994;
        border: 3px solid #005994;
        background: #fff;
      }
    }

    .subContent {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-top: 2rem;
      flex: 1;

      .subTitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #005994;
        margin: 0 1.5rem;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #005994;
      border-bottom: 3px solid #e33c90;
      border-radius: 20px;
      min-height: 120px;
      padding: 1rem;
      cursor: pointer;
      margin: 1rem;

      &__title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: #ffffff;
        flex: 1;
      }
    }

    .counselor {
      background: #787d86;
      box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.25),
        inset 0px -3px 4px rgba(0, 0, 0, 0.25);
    }

    .addItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 3px dashed #005994;
      background: #fff;
      padding: 1rem;
      border-radius: 20px;
      cursor: pointer;
      row-gap: 0.5rem;
      min-height: 120px;
      margin: 1rem;

      .addIcon {
        font-weight: 700;
        font-size: 56px;
        line-height: 63px;
        text-align: center;
        color: #005994;
      }
    }

    .addCounselor {
      border: 3px dashed #787d86;

      .addIcon {
        color: #787d86;
      }
    }

    .outlinedBtn {
      border-radius: 8px;
      font-weight: 700;
      font-size: 1.2rem;
      text-transform: none;
      padding: 0.5rem 1.5rem;
      width: 270px;
      margin: 0 auto;
      color: #005994;
      border: 3px solid #005994;
      background: #fff;

      &:hover {
        color: #005994;
        border: 3px solid #005994;
        background: #fff;
      }
    }
  }
}

.addProCounsContainer {
  width: 100%;

  .itemContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 2rem;

    .itemTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #363636;
      text-align: start;
    }

    .content {
      display: flex;
      column-gap: 1.5rem;
      align-items: center;

      .addBtn {
        background: #005994;
        border: 1px solid #e33c90;
        border-radius: 10px;
        text-transform: none;
        min-width: 100px;
      }
    }
  }

  .addSiteTitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #787d86;
  }

  .input {
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    width: 300px;
  }
}

@media screen and (max-width: 1100px) {
  .userDasboardPage {
    flex-direction: column;
  }
}

@media screen and (max-width: 885px) {
  .editSiteDetailsPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
        .addMemberBtn {
          align-self: flex-end;
        }
      }
    }
  }
}
@media screen and (max-width: 520px) {
  .editSiteDetailsPage {
    padding: 0 1rem;
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }

    &__membersContainer {
      padding: 2rem 1rem;

      .subHeader .btnContainer {
        flex-direction: column;
        row-gap: 1rem;
        align-items: center;
      }

      .gridContainer {
        max-height: max-content;
      }
    }
  }
  .addProCounsContainer .itemContainer {
    align-items: center;
    .content {
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;
    }
  }
}

@media screen and (max-width: 445px) {
  .editSiteDetailsPage {
    &__imageContainer .img {
      width: 350px;
    }
  }
}
