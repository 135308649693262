.showMemberDetailsPagePC {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      margin: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin-bottom: 3rem;
    flex: 1;

    .subHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e0e0e0;

      .btnContainer {
        display: flex;
        column-gap: 1.5rem;

        .editMemberBtn,
        .deleteMemberBtn {
          font-weight: 500;
          font-size: 1.2rem;
          text-transform: none;
          padding: 0.25rem;
          background: #ffffff;
          border-radius: 8px;
        }
        .editMemberBtn {
          border: 4px solid #005994;
          color: #005994;
        }

        .deleteMemberBtn {
          border: 5px solid #e33c90;
          color: #e33c90;
        }
      }
    }

    .subMain {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      flex: 1;

      &__memberInfo {
        display: flex;
        column-gap: 7rem;
        margin: 1rem 0;
      }

      &__goalsContainer {
        display: flex;
        justify-content: space-between;

        .goals {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        .btnContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin: 2rem;
          row-gap: 1.5rem;

          .btn {
            width: 345px;
            min-height: 56px;
            color: #fff;
            font-size: 1.1rem;
            text-transform: none;
            border-radius: 10px;
          }

          .redoRiskBtn {
            background: #005994;
          }
          .showRiskBtn {
            background: #e33c90;
          }
        }
      }
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1140px) {
  .showMemberDetailsPagePC__contentContainer .subMain__goalsContainer {
    flex-direction: column-reverse;
  }
  .showMemberDetailsPagePC__contentContainer .subHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;

    .btnContainer {
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 885px) {
  .showMemberDetailsPagePC__contentContainer .subHeader {
    .btnContainer {
      flex-direction: column;
      align-self: flex-end;
      row-gap: 1rem;
    }
  }

  .showMemberDetailsPagePC__contentContainer .subMain__goalsContainer .goals {
    max-height: max-content;
  }
}
@media screen and (max-width: 775px) {
  .showMemberDetailsPagePC {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .showMemberDetailsPagePC__contentContainer {
    .subMain__memberInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 520px) {
  .showMemberDetailsPagePC {
    padding: 0 1rem;
    &__contentContainer {
      padding: 2rem 1rem;
    }
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
  }
}
@media screen and (max-width: 475px) {
  .showMemberDetailsPagePC__contentContainer {
    .subMain__goalsContainer .btnContainer {
      .redoRiskBtn,
      .showRiskBtn {
        width: 100%;
      }
    }
  }
}
