.customBackBtnContainer {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
  max-width: 1536px;

  .customBtn {
    background-color: #e33c90;
    border-radius: 10px;
    min-height: 50px;
    text-transform: none;
    min-width: 242px;
    padding: 0.3rem 2rem;
    font-weight: 700;
    font-size: 1.1rem;
    align-self: flex-end;

    &:hover {
      background-color: #e30e79;
    }
  }
}
