.managerDashboardPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .familyLogoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem 1rem;
    }

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem;
    background-color: #e6eff5;

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin-bottom: 3rem;

    .subHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 2rem;
    }

    .title {
      color: #005994;
      padding-bottom: 1.5rem;
      width: 100%;
      display: flex;
      align-items: flex-end;
    }

    .license-info {
      &__title {
        font-size: 24px;
        font-weight: 500;
      }
    }

    .btn {
      border-radius: 8px;
      font-weight: 700;
      font-size: 1.2rem;
      text-transform: none;
      padding: 0.5rem;
      width: 270px;
      margin: 0 0.75rem;
    }

    .containedBtn {
      background: #005994;
      color: #fff;
    }

    .outlinedBtn {
      color: #005994;
      border: 3px solid #005994;
      background: #fff;
    }
  }

  .site {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background: #005994;
    border-bottom: 3px solid #e33c90;
    border-radius: 20px;
    min-height: 200px;
    padding: 1rem;

    &__title {
      font-weight: 700;
      font-size: 38px;
      line-height: 49px;
      text-align: center;
      color: #ffffff;
      margin-top: 1rem;
    }

    &__desc {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #ececec;
      margin-top: 0.5rem;
    }

    .details {
      font-weight: 500;
      font-size: 0.9rem;
      margin: 0.175rem 0;
      text-align: center;
      text-decoration-line: underline;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  .addNew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #005994;
    padding: 1rem;
    border-radius: 20px;
    height: 181px;
    cursor: pointer;
    row-gap: 0.5rem;
    min-height: 200px;

    .addIcon {
      font-weight: 700;
      font-size: 56px;
      line-height: 63px;
      text-align: center;
      color: #005994;
    }
  }

  .subFooter {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}

.addSiteContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;

  .addSiteTitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #787d86;

    strong {
      color: #005994;
    }
  }

  .input {
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    width: 300px;
  }
}

@media screen and (max-width: 1200px) {
  .managerDashboardPage__container {
    min-height: 55vh;
    height: max-content !important;

    .subHeader {
      flex-direction: column-reverse;
      align-items: flex-start;

      .btnContainer {
        align-self: flex-end;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .managerDasboardPage {
    flex-direction: column;
  }
}

@media screen and (max-width: 885px) {
  .managerDashboardPage {
    &__container {
      .subHeader {
        flex-direction: column-reverse;
        gap: 2rem;
        align-items: flex-start;
        .addMemberBtn {
          align-self: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .managerDashboardPage {
    padding: 0 1rem;
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
    &__container {
      padding: 2rem 1rem;

      .subHeader .btnContainer {
        flex-direction: column;
        row-gap: 1rem;
        align-items: center;
      }

      .gridContainer {
        max-height: max-content;
      }
    }
  }
}

@media screen and (max-width: 445px) {
  .managerDashboardPage {
    &__imageContainer .img {
      width: 350px;
    }
  }
}
