.signUpPage {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;
  row-gap: 7rem;
  margin: 2rem 0;
  width: 100%;
  flex: 1;
  padding: 1rem;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
  }

  &__signUp {
    padding: 3rem;
    max-width: 750px;
    width: 100%;
    position: relative;

    .imgHintContainer {
      width: 204px;
      height: 135px;
      position: absolute;
      top: -115px;
      left: 0px;

      .imgHint {
        height: 100%;
        width: auto;
        object-fit: cover;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .forgot-password {
      margin-top: 0.4rem;
      align-self: flex-end;
      cursor: pointer;

      &:hover {
        color: #005994;
      }
    }

    .login-btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
    }

    .sign-up {
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 1100px) {
  .signUpPage {
    flex-direction: column;
  }
}
