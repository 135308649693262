body {
  margin: 0;
  @font-face {
    font-family: "Noto Sans";
    src: url("assets/fonts/NotoSans-Regular.ttf") format("tff");
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Noto Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
