.editSitePage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .familyLogoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem 1rem;
    }

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__membersContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem;
    background-color: #e6eff5;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin-bottom: 3rem;
    flex: 1;

    .subHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btnContainer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-self: flex-end;
      }

      &__desc {
        font-weight: 350;
        font-size: 18px;
        line-height: 26px;
        color: #333333;
      }

      .btnContainer {
        .btn {
          border-radius: 8px;
          font-weight: 700;
          font-size: 1.2rem;
          text-transform: none;
          padding: 0.5rem;
          width: 270px;
          margin: 0 0.75rem;
        }

        .containedBtn {
          background: #005994;
          color: #fff;
        }

        .outlinedBtn {
          color: #005994;
          border: 3px solid #005994;
          background: #fff;
        }
      }
    }

    .siteContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      background: #005994;
      border-bottom: 3px solid #e33c90;
      border-radius: 20px;
      min-height: 200px;
      padding: 1rem;

      .site {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        flex: 1;

        &__title {
          font-weight: 700;
          font-size: 38px;
          line-height: 49px;
          text-align: center;
          color: #ffffff;
        }

        &__desc {
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #ececec;
          margin-top: 0.5rem;
        }

        &__editLink {
          font-weight: 500;
          font-size: 0.9rem;
          margin: 0.175rem 0.5rem;
          text-align: center;
          text-decoration-line: underline;
          align-self: flex-end;
          cursor: pointer;
        }
      }

      .details {
        font-weight: 500;
        font-size: 0.9rem;
        margin: 0.175rem 0;
        text-align: center;
        text-decoration-line: underline;
        align-self: flex-end;
        cursor: pointer;
      }
    }

    .noSite {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #9ea6b3;
      border-bottom: 3px solid #e33c90;
      border-radius: 20px;
      min-height: 200px;
      padding: 1rem;

      &__title {
        font-weight: 700;
        font-size: 38px;
        line-height: 49px;
        text-align: center;
        color: #ffffff;
      }
    }

    .addNew {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 3px dashed #005994;
      padding: 1rem;
      border-radius: 10px;
      height: 181px;
      cursor: pointer;
      row-gap: 0.5rem;
      min-height: 200px;

      .addIcon {
        font-weight: 700;
        font-size: 56px;
        line-height: 63px;
        text-align: center;
        color: #005994;
      }
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .editSitePage__membersContainer {
    min-height: 55vh;
    height: max-content !important;

    .subHeader {
      flex-direction: column;
      row-gap: 1rem;
      align-items: flex-start;

      .btnContainer {
        align-self: flex-end;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .editSitePage {
    flex-direction: column;
  }
}

@media screen and (max-width: 885px) {
  .editSitePage {
    &__membersContainer {
      .subHeader {
        flex-direction: column;
        row-gap: 2.5rem;
        align-items: flex-start;
        .addMemberBtn {
          align-self: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .editSitePage {
    padding: 0 1rem;
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
    &__membersContainer {
      padding: 2rem 1rem;

      .subHeader .btnContainer {
        flex-direction: column;
        row-gap: 1rem;
        align-items: center;
      }

      .gridContainer {
        max-height: max-content;
      }
    }
  }
}

@media screen and (max-width: 445px) {
  .editSitePage {
    &__imageContainer .img {
      width: 350px;
    }
  }
}
