.cookie {
  position: absolute;
  bottom: 0;
  max-width: 1384px;
  min-height: 142px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.49);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0 0;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  column-gap: 25px;
  z-index: 3;

  &__info {
    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;
      text-align: start;
      color: #787d86;
      margin-bottom: 8px;
    }

    .desc {
      max-width: 1095px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: justify;
      color: #787d86;
    }
  }

  &__btnGroup {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .btn {
      width: 183px;
      height: 45px;
      border-radius: 10px;
    }
    .contained {
      background: #005994;
      border: 1px solid #f7c5c5;
      color: #fff;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;
      text-align: center;

      &:hover,
      &:focus,
      &:active {
        background: #005994;
        border: 1px solid #f7c5c5;
        color: #fff;
      }
    }
    .outlined {
      background: #ffffff;
      border: 1px solid #9ea6b3;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;

      &:hover,
      &:focus,
      &:active {
        background: #ffffff;
        border: 1px solid #9ea6b3;
        color: #787d86;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .cookie {
    flex-direction: column;
    row-gap: 2rem;
  }
}
