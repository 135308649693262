.fluorideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  width: 100%;

  .content {
    display: flex;
    column-gap: 1.5rem;
    width: 100%;
  }

  .fluorideContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin-top: 1rem;
    row-gap: 1.5rem;
    min-height: 55vh;
    padding: 0 1.5rem;

    .questionBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 0.5rem;
      margin: 2rem 0;
      width: 100%;

      .bubbleContainer {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 20%;
      }

      .questionTitle {
        font-weight: 500;
        font-size: 28px;
        line-height: 50px;
        text-align: center;
        color: #333333;
        max-width: 800px;
      }
      .choicesBtnContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
        margin-top: 1.5rem;

        .choiceBtn {
          width: 320px;
          height: 60px;
          background: #c2c2c2;
          border-radius: 10px;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: rgba(0, 0, 0, 0.5);
          text-transform: none;
        }

        .choiceBtn--selected {
          background: #005994;
          border-bottom: 3px solid #e33c90;
          border-radius: 10px;
          color: #fff;
        }
      }
    }
  }

  .btn {
    min-width: 300px;
    min-height: 50px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    background: #005994;
    border-radius: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1rem;
    text-transform: none;
  }

  .bubblesContainer {
    min-height: 100%;
    margin-top: 95px;
    border-left: 3px solid #666666;

    .bubbleTextContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem 3rem;
      // border-left: 3px solid #666666;
      row-gap: 1rem;

      .bubbleText {
        background: #ffffff;
        border: 1px solid #787d86;
        border-radius: 20px;
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
        width: 350px;

        .text {
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 35px;
          text-align: center;
          color: #646870;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .fluorideContainer .fluorideContent .questionBox .bubbleContainer {
    flex-direction: column;
  }
}
