.aboutUsPage {
  height: 100%;
  max-width: 1217px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem;
  margin-top: 0;
  row-gap: 1.4rem;
  padding: 1rem 1.5rem;

  .privacyTitle {
    font-weight: 700;
    font-size: 34px;
    line-height: 35px;
    color: #000000;
    margin: 2rem 0;
  }
  .desc {
    font-weight: 400;
    font-size: 1.2rem;
  }
  .subTitle {
    font-weight: 500;
    font-size: 1.6rem;
    margin-top: 2rem;
  }
  .italicTitle {
    font-style: italic;
    font-size: 1.3rem;
  }
}

.privacyBtn {
  min-width: 220px;
  max-width: 300px;
  width: 100%;
  min-height: 60px;
  background: #005994 !important;
  border: 1px solid #f7c5c5 !important;
  border-radius: 18px !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 29px !important;
  text-align: center;
  color: #ffffff !important;
  margin-bottom: 65px !important;
  text-transform: none !important;
}
