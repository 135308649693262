.deleteModal {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  width: calc(100% - 16px);
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 6px;
  padding: 1.5rem 3rem;

  &__img {
    width: 70px;
    height: 80px;
    object-fit: contain;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: center;
    width: 100%;

    .title {
      font-size: 26px;
      color: #285cb8;
      text-align: center;
    }

    .childrenContainer {
      display: flex;
      justify-content: center;
      flex: 1;
    }

    .btnContainer {
      align-self: flex-end;
      display: flex;
      column-gap: 1.5rem;
      justify-content: flex-end;

      .btn {
        min-height: 50px;
        border-radius: 10px;
        text-transform: none;
        font-size: 1rem;
        max-width: 400px;
        width: 100%;
      }

      .deleteBtn {
        background: #005994;
        color: #fff;
        flex: 1;
      }
      .removeBtn {
        background: #e33c90;
        color: #fff;
        flex: 1;
      }
      .cancelBtn {
        background: #ffffff;
        color: #005994;
        border: 1px solid #787d86;
        flex: 0.2;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .deleteModal {
    flex-direction: column;
    align-self: auto;
    row-gap: 1rem;
    width: calc(100% - 32px);
  }
}
@media screen and (max-width: 800px) {
  .deleteModal__content .btnContainer {
    flex-direction: column;
    align-self: auto;
    row-gap: 1rem;
  }
}
