.userReportPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem;
    background-color: #e6eff5;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    margin-bottom: 3rem;

    .btn {
      background-color: #fff;
      color: #005994;
      border-radius: 10px;
      text-transform: none;
      font-size: 1.1rem;
      border: 2px solid #ccc;
      min-height: 50px;

      &:hover {
        background-color: #fff;
        border: 2px solid #005994;
      }
    }
  }
  &__content {
    width: 100%;

    .table-container {
      border-radius: 6px;
    }

    .table-header {
      background-color: #e6eff5;
      border-radius: 6px;
      .table-cell {
        padding: 20px 14px;
        font-size: 24px;
      }
    }
    .table-row {
      .table-cell {
        padding: 16px 14px;
        font-size: 18px;
      }
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1100px) {
  .userReportPage {
    &__header {
      flex-direction: column-reverse;
      gap: 1.5rem;
    }
  }
}

@media screen and (max-width: 885px) {
  .userReportPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
        .addMemberBtn {
          align-self: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .userReportPage {
    padding: 0 1rem;
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
    &__membersContainer {
      padding: 2rem 1rem;

      .gridContainer {
        max-height: max-content;
      }
    }
  }
}
@media screen and (max-width: 445px) {
  .userReportPage {
    &__imageContainer .img {
      width: 350px;
    }
  }
}
