.counselorDashboardPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .familyLogoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem 1rem;
    }

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__membersContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    margin: 1rem;
    background-color: #e6eff5;

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin-bottom: 3rem;
    flex: 1;

    .subHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .addMemberBtn {
        background: #005994;
        border-radius: 8px;
        font-weight: 700;
        font-size: 1.2rem;
        text-transform: none;
        padding: 0.5rem;
        width: 100%;
      }
    }

    .member {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 1rem;
      background: #e33c90;
      border-bottom: 3px solid #005994;
      border-radius: 6px;
      height: 100%;

      .memberLogo {
        margin-bottom: 0.5rem;
      }

      .memberName {
        font-weight: 900;
        font-size: 1.25rem;
        text-align: center;
        margin: 0.175rem 0;
        text-align: center;
      }

      .age {
        font-weight: 500;
        font-size: 1rem;
        margin: 0.175rem 0;
        text-align: center;
      }

      .details {
        font-weight: 500;
        font-size: 0.9rem;
        margin: 0.175rem 0;
        text-align: center;
        text-decoration-line: underline;
        align-self: flex-end;
        cursor: pointer;
        margin-top: auto;
      }
    }

    .add-member {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dashed #000000;
      padding: 1rem;
      border-radius: 10px;
      height: 181px;
      cursor: pointer;
      row-gap: 0.5rem;
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1100px) {
  .userDasboardPage {
    flex-direction: column;
  }
}

@media screen and (max-width: 885px) {
  .counselorDashboardPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
        .addMemberBtn {
          align-self: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .counselorDashboardPage {
    padding: 0 1rem;
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
    &__membersContainer {
      padding: 2rem 1rem;

      .gridContainer {
        max-height: max-content;
      }
    }
  }
}
@media screen and (max-width: 445px) {
  .counselorDashboardPage {
    &__imageContainer .img {
      width: 350px;
    }
  }
}
