.toothLogo {
  width: 50px;
  height: auto;
}
.textLogo {
  height: 20px;
}

@media screen and (max-width: 500px) {
  .toothLogo {
    height: 40px;
    width: 38px;
  }
  .textLogo {
    height: 20px;
  }
}
