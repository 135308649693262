.managerAccountPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin: 1rem;
    margin-bottom: 3rem;

    .subHeader {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #e0e0e0;

      .btn {
        border-radius: 8px;
        font-weight: 700;
        font-size: 1.2rem;
        text-transform: none;
        padding: 0.5rem 1.5rem;
        min-width: 270px;
        margin-left: auto;
        margin-top: 0;
      }

      .containedBtn {
        background: #005994;
        color: #fff;
      }
    }

    .subTitle__info {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #005994;
      margin-top: 4rem;
      margin-bottom: 1rem;
    }

    .subMain {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      &__memberInfo {
        display: flex;
        column-gap: 7rem;
        margin: 1rem 0;
        min-width: 360px;

        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          color: #000000;
          margin-bottom: 0.5rem;
        }
      }
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1140px) {
  .managerAccountPage__contentContainer .subHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 2rem;

    .btnContainer {
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 885px) {
  .managerAccountPage__contentContainer .subHeader {
    .btnContainer {
      flex-direction: column;
      align-self: flex-end;
      row-gap: 1rem;
    }
  }
}
@media screen and (max-width: 775px) {
  .managerAccountPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .managerAccountPage__contentContainer {
    .subMain__memberInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-left: 1rem;
    }
  }
}
@media screen and (max-width: 520px) {
  .managerAccountPage {
    padding: 0 1rem;
    &__contentContainer {
      padding: 2rem 1rem;
    }
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
  }
}
