.goalRiskAreasContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;

  .title {
    font-weight: 700;
    color: #e33c90;
  }

  .areasContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .btn {
    min-width: 300px;
    min-height: 50px;
    color: #fff !important;
    font-size: 1.1rem;
    font-weight: 700;
    background: #005994 !important;
    border-radius: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    text-transform: none;
    align-self: flex-end;
    margin-top: 3rem;

    // &:hover {
    //   background: #868c96;
    // }
  }
}
