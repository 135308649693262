.meal-card {
  border-radius: 18px;
  box-shadow: 0px 4px 10px 3px #00000040;
  max-width: 300px;
  width: 100%;
  min-width: 280px;
  min-height: 135px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 1rem;
  background-color: #fff;
  margin: 0 auto;
  padding: 10px;

  .meal-title {
    color: #005994;
    text-align: end;
    font-size: 18px;
    text-wrap: wrap;
    flex: 1;
  }

  .meal-img {
    border: 3px solid #9ea6b2;
    border-radius: 14px;
    width: 132px;
    height: 118px;
    margin: auto;
  }

  .remove-icon-container {
    background-color: #832727;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -18px;
    right: -18px;
    cursor: pointer;
  }
}

@media screen and (max-width: 380px) {
  .meal-card {
    flex-direction: column;
    min-width: 260px;

    .meal-title {
      text-align: center;
    }
  }
}
