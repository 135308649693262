.foodDrinksModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 700px;
  overflow-y: auto;
  row-gap: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: calc(100% - 20px);
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 6px;
  padding: 1.5rem;

  .title {
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
    margin: 1.5rem 0;

    .titleText {
      font-weight: 600;
      font-size: 2rem;
      line-height: 54px;
      text-align: center;
      color: #285cb8;
    }

    .img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
  }

  .btn {
    width: 200px;
    min-height: 50px;
    border-radius: 10px;
    background-color: #005994;
    color: #fff;
    text-transform: none;
  }
}
