.riskAreasPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    background-color: #e6eff5;

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 65vh;
    margin-bottom: 3rem;

    .subHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .subMain {
      display: flex;
      margin-top: 1rem;
      flex: 1;
      height: 100%;
      justify-content: space-between;

      .riskAreasContainer {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }
    }

    .subFooter {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 1140px) {
  .riskAreasPage__contentContainer .subMain__goalsContainer {
    flex-direction: column-reverse;
  }
  .riskAreasPage__contentContainer .subHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;

    .btnContainer {
      align-self: flex-end;
    }
  }
}
@media screen and (max-width: 1000px) {
  .riskAreasPage__contentContainer {
    .subMain {
      flex-direction: column;
      justify-content: inherit;
      row-gap: 3rem;
    }
  }
}

@media screen and (max-width: 885px) {
  .riskAreasPage__contentContainer .subHeader {
    .btnContainer {
      flex-direction: column;
      align-self: flex-end;
      row-gap: 1rem;
    }
  }
}
@media screen and (max-width: 775px) {
  .riskAreasPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .riskAreasPage {
    padding: 0 1rem;
  }
  .riskAreasPage__contentContainer {
    .subMain__memberInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 520px) {
  .riskAreasPage {
    padding: 0 1rem;
    &__contentContainer {
      padding: 2rem 1rem;
    }
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
  }
}
@media screen and (max-width: 475px) {
  .riskAreasPage__contentContainer {
    .subMain__goalsContainer .btnContainer {
      .redoRiskBtn,
      .showRiskBtn {
        width: 100%;
      }
    }
  }
}
