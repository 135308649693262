.resetPasswordPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  padding: 0 1.5rem 4rem;

  .firstTypo {
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    color: #000000;
    margin-bottom: 1.5rem;
  }

  .secondTypo {
    letter-spacing: 0.00938em;
    font-size: 28px;
    font-weight: 400;
    max-width: 900px;
    line-height: 1.5;
  }

  .thirdTypo {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #a3a3a3;
    max-width: 600px;
    margin-top: 3rem;
  }
  .fourthTypo {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 3rem;
  }

  .passwordInput {
    max-width: 410px;
    width: 100%;
    margin-top: 1.5rem;
  }

  .btn {
    border-radius: 8px;
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: none;
    padding: 0.5rem;
    width: 270px;
    margin: 0 0.75rem;
    background: #005994;
    color: #fff;
    margin-top: 3rem;
  }

  @media screen and (max-width: 695px) {
    .firstTypo {
      font-size: 48px;
      width: 100%;
      text-align: start;
    }

    .secondTypo,
    .thirdTypo {
      width: 100%;
      text-align: start;
    }
  }
}
