.riskAreaBox {
  display: flex;
  max-width: 650px;
  width: 100%;
  margin: 1.5rem 0;

  .leftBox {
    min-height: 64px;
    height: auto;
    background-color: #005994;
    border: 3px solid #005994;
    border-radius: 14px 0 0 14px;
  }
  .leftBox--selectedGoal {
    background: #e33c90;
    border: 3px solid #e33c90;
    border-radius: 14px 0 0 14px;
  }

  .rightBox {
    background-color: #fff;
    border: 3px solid #005994;
    border-radius: 0 14px 14px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    flex: 1;
    width: 100%;

    .rightBox__title {
      font-weight: 700;
      font-size: 1.5rem;
      text-align: start;
      color: #005994;
    }

    .rightBox__doneBox {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .teethIcon {
        width: 30px;
        height: 30px;
      }

      .doneText {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .rightBox--done {
    background-color: #e0e0e0;
  }
  .rightBox--selectedGoal {
    background: #005994;
    border: 3px solid #e33c90;

    .rightBox__title {
      color: #fff;
    }
  }

  .rightBox--selectedArea {
    background: #e33c90;

    .rightBox__title {
      color: #fff;
    }
  }
}

@media screen and (max-width: 600px) {
  .riskAreaBox .rightBox {
    padding: 0 1rem;

    .rightBox__title {
      font-size: 1.2rem;
    }
  }
}
