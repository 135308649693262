.goalSafeAreasContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;

  .title {
    font-weight: 700;
    color: #e33c90;
  }

  .btn {
    min-width: 300px;
    min-height: 50px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    background: #005994;
    border-radius: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    text-transform: none;
    align-self: flex-end;
    margin-top: 3rem;
  }
}
