.startAssessmentPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4rem;
  padding: 0 2rem;
  margin-top: 1rem;
  width: 100%;
  max-width: 1536px;
  flex: 1;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    width: 100%;
    overflow-x: auto;

    .userInfoContainer {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    .btn {
      background-color: #005994;
      color: #fff;
      margin-top: 1.5rem;
      border-radius: 10px;
      min-height: 60px;
    }
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #e6eff5;
    align-items: center;

    background-repeat: no-repeat;
    background-position: center center;
    min-height: 65vh;
    width: 100%;
    margin: 1rem;
    margin-bottom: 3rem;
    padding: 2rem 1rem;

    .subHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;
      background: #005994;
      border-radius: 10px 10px 0px 0px;
    }

    .contentContainer {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      row-gap: 2rem;
      padding: 1rem;
      height: 100%;
      max-width: 1200px;
      width: 100%;

      .title {
        font-weight: 700;
        // font-size: 40px;
        line-height: 54px;
        text-align: right;
        letter-spacing: -0.2px;
        color: #e33c90;
      }

      .user {
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 24px;
        text-align: right;
        color: #005994;
      }

      .content {
        background: linear-gradient(180deg, #ffffff 99.99%, #eddbff 100%);
        border: 2px solid #9ea6b3;
        border-radius: 16px;
        padding: 1rem 1.75rem;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin-top: 2rem;

        .teethIcon {
          width: 80px;
          height: 80px;
          object-fit: contain;
          position: absolute;
          bottom: -50px;
          left: -20px;
        }

        .contentText {
          font-size: 25px;
          line-height: 38px;
          text-align: center;
          margin-bottom: 10px;
          color: #676767;
        }
      }
      .btn {
        max-width: 300px;
        width: 100%;
        min-height: 50px;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 700;
        background: #005994;
        border-radius: 10px;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 1rem;
        text-transform: none;
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .memberDetailsstartAssessmentPage__contentContainer .subMain__goalsContainer {
    flex-direction: column-reverse;
  }
  .memberDetailsstartAssessmentPage__contentContainer .subHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;

    .btnContainer {
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 885px) {
  .memberDetailsstartAssessmentPage__contentContainer .subHeader {
    .btnContainer {
      flex-direction: column;
      align-self: flex-end;
      row-gap: 1rem;
    }
  }
}
@media screen and (max-width: 775px) {
  .memberDetailsstartAssessmentPage {
    &__membersContainer {
      .subHeader {
        flex-direction: column-reverse;
        row-gap: 2.5rem;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .memberDetailsstartAssessmentPage__contentContainer {
    .subMain__memberInfo {
      flex-direction: column;
      row-gap: 1rem;
      margin-left: 1rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .startAssessmentPage {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 520px) {
  .startAssessmentPage {
    padding: 0 1rem;
    &__contentContainer {
      padding: 2rem 1rem;

      .subMain {
        padding: 2rem 0;

        .contentContainer {
          padding: 1rem 0;
        }
      }
    }
    &__intro .userInfoContainer {
      flex-direction: column;
      row-gap: 0.5rem;
      align-items: flex-start;
    }
  }
}
@media screen and (max-width: 475px) {
  .startAssessmentPage__contentContainer {
    .subMain__goalsContainer .btnContainer {
      .redoRiskBtn,
      .showRiskBtn {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .startAssessmentPage__contentContainer .subMain .contentContainer .content {
    padding: 1.75rem 1rem;
    .contentText {
      text-align: start;
      font-size: 20px;
    }
  }
}
