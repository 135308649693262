.guidePopupContainer {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  .guidePopup {
    display: flex;
    align-items: center;
    column-gap: 21px;

    .teethIcon {
      width: 83px;
      height: 90px;
    }

    .guide {
      padding: 12px;
      background: linear-gradient(180deg, #ffffff 99.99%, #eddbff 100%);
      border: 2px solid #9ea6b3;
      border-radius: 16px;
      display: flex;
      flex-direction: column;

      .closeIcon {
        align-self: flex-end;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 34px;
        text-align: center;
        color: #676767;
      }
    }
  }
}
