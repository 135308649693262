.customHintContainer {
  background: #ffffff;
  border: 1px solid #787d86;
  border-radius: 20px;
  padding: 1.5rem;
  max-width: 380px;
  width: 100%;
  min-height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .teethIcon {
    width: 80px;
    height: auto;
    object-fit: contain;
    position: absolute;
  }
  .hint__text {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 26px;
    text-align: center;
    color: #646870;
  }
}
