.container {
  display: flex;
  justify-content: center;
  // flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  column-gap: 4rem;

  .container__content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
  }

  .title {
    font-weight: 500;
    font-size: 2rem;
    color: #333333;
    line-height: 42px;
    text-align: center;
    max-width: 700px;
  }

  .congra__title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 52px;
    text-align: center;
    color: #e33c90;
    max-width: 700px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    max-width: 700px;

    text-align: center;

    color: #787d86;
  }

  &__content {
    .radioItem span {
      font-weight: 400;
      font-size: 1.4rem;
    }

    .C2__textarea {
      width: 300px;
      height: 100px !important;
      margin-left: 0.5rem;
      border-radius: 6px;
      padding: 0.5rem;
      font-size: 1rem;
    }
    .C3__textarea {
      max-width: 700px;
      width: 100%;
      height: 200px !important;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      font-size: 1.3rem;
    }
  }
  &__btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1.5rem;

    .C1__btn {
      min-width: 300px;
      height: 50px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 1.1rem;
      font-weight: 700;
      background: #c2c2c2;
      border-radius: 10px;

      &:hover {
        background-color: #c2c2c2;
      }
    }
    .C2__btn {
      min-width: 300px;
      height: 50px;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 700;
      background: #005994;
      border-radius: 10px;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 1rem;
      text-transform: none;
    }
  }
}
.goalSummary {
  padding: 2.5rem 1.5rem;
  max-width: 450px;
  width: 100%;
  border-radius: 10px;
  font-weight: 500;

  .title {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #e33c90;
    margin-bottom: 1rem;
  }
  .goalTitle {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #07120d;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.5rem 1rem;
  }

  .goalSteps {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.1rem;
    .goalStep {
      display: flex;
      column-gap: 4px;
      align-items: center;

      .ruleIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        min-width: 8px;
        height: 8px;
        padding: 0 auto;
        font-weight: 500;
        font-size: 1.1rem;
        // background: #e33c90;
        background: #575757;
        // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .container {
    flex-direction: column-reverse;
  }
}
