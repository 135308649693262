.videoEmbed {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 645px) {
  .iframe {
    max-width: 360px;
    height: 260px;
  }
}
