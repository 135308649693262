.step-information {
  display: flex;
  margin: 5px 0;

  .title {
    color: #005994;
    font-size: 1.5rem;
  }
  .desc {
    color: #787d86;
    font-size: 1.5rem;
    margin-left: 5px;
    flex: 1;
  }
}

@media screen and (max-width: 1420px) {
  .step-information {
    flex-direction: column;
    margin: 8px 0;
  }
}

@media screen and (max-width: 575px) {
  .step-information {
    .title,
    .desc {
      font-size: 1.25rem;
    }
  }
}
