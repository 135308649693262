.previousGoal {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: 550px;
  padding: 1rem 0;
  background: #c5c5c5;
  margin-bottom: 1.5rem;

  &__title {
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    color: #005994;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
  }
  &__content {
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;

    .goalStep {
      display: flex;
      column-gap: 1rem;
      align-items: center;

      .ruleIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        min-width: 8px;
        height: 8px;
        padding: 0 auto;
        font-weight: 500;
        font-size: 1.1rem;
        // background: #e33c90;
        background: #575757;
        // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 695px) {
  .previousGoal {
    width: auto;
  }
}
