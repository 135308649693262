.ExpiredLicensePage {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;

  .firstTypo {
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    color: #000000;
  }

  .secondTypo {
    font-weight: 600;
    font-size: 50px;
    text-align: center;
    color: #005994;
  }

  .thirdTypo {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #a3a3a3;
  }
}
